body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #db6b97;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.SpørsmålsComponent {
  background-color: #f2ffe9;
  border: 8px solid #a6cf98;
  border-radius: 5px;
  color: #000;
  font-size: 18px;
  margin: 10% auto 0;
  padding: 40px;
  width: 50%;
  transform: translateZ(10px) translateY(10px);
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  height: 150px;
}

.SvarBox {
  font-size: 18px;
  background-color: #f2ffe9;
  display: flex;
  margin: auto; /* Midtstill boksen */
  margin-top: -0.3cm; /* setter svarbox tett inntill sprsmboksen */
  margin-bottom: 20px;
  width: 90%; /* Gjør boksen responsiv */
  max-height: 450px;
  border-radius: 5px;
  border-style: solid;
  border-color: #a6cf98;
  border-width: 6px;
  line-height: 1.7;
  padding-left: 25px;
  padding-top: 25px;
}

/* Media query for skjermer mindre enn 600px */
@media (max-width: 600px) {
  .SvarBox {
    font-size: 16px; /* Mindre tekststørrelse for små skjermer */
    width: 90%;
  }
}

.svarliste {
  color: #f2ffe9;
  text-align: center;
  width: max-content; /* Setter bredden til innholdets bredde */
  margin: 0 auto; /* Sentrerer boksen horisontalt */
  /* Legg til andre stiler som padding, border, etc., om nødvendig */
  margin-top: 15%;
  margin-bottom: 15%;
  font-size: 1.3rem;
  width: 65%;
  max-width: 65%;
  line-height: 1.8;
}
.svarliste span {
  color: #f2ffe9;
  display: block; /* Gjør hvert span-element til en blokk, slik at det tar opp hele bredden */
  border-bottom: 1px solid white; /* Setter en kant rundt hver tekstlinje */
  margin: 5px 0; /* Legger til margin over og under hver boks */
  padding: 5%; /* Legger til litt plass inne i hver boks */
  /* Legg til eventuelle andre stildefinisjoner her, som bakgrunnsfarge eller font-stil */
}

.klikkbart-svar {
  cursor: pointer;
  /* Legg til ekstra stiler her, som fargeendring ved hover */
}

.footer {
  color: white;
  text-align: center;
  padding-bottom: 30px;
}

.hovedoverskrift {
  color: #f2ffe9;
  text-align: center; /* Senterer teksten */
  margin-top: 50px;
  font-size: 2.5rem;
  padding: 5%;
  border-bottom: 1px solid white;
  margin: 0 auto;
}

.lagreknapp {
  background-color: #a6cf98; /* Lys blå bakgrunnsfarge */
  color: black; /* Hvit tekstfarge */
  border: none; /* Fjerner standard kantlinje */
  padding: 10px 20px; /* Legger til innvendig plass */
  font-size: 16px; /* Øker skriftstørrelsen */
  border-radius: 5px; /* Runde hjørner */
  cursor: pointer; /* Endrer markøren til en peker */
  transition: background-color 0.3s ease; /* Gjør overgangen mykere når musen går over */
}

.content {
  width: 90%;
  max-width: 600px;
  margin: auto;
}

.lagrecontainer {
  text-align: center;
}

.lagreknapp:hover {
  background-color: #87ceeb; /* Litt mørkere blå for hover-effekt */
}

.arrow {
  background-image: url('data:image/svg+xml,\00003csvg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">\00003cpath fill="%239aa0a6" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z">\00003c/path>\00003c/svg>');
  display: inline-block;
  height: 24px;
  width: 24px;
}

.tidligere-svar-rad {
  display: "flex";
  justify-content: "space-between";
}
